import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

function SEO({ description, lang, meta, title, image = 'https://www.qlue.co.id/images/logo.png' }) {
  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={ title }
      titleTemplate={ 'Qlue Smart City | %s' }
      meta={ [
        // -- Primary Meta Tags --
        {
          name    : 'title',
          content : title
        },
        {
          name    : 'description',
          content : description
        },
        // -- og tag --
        {
          property : 'og:type',
          content  : 'website'
        },
        {
          property : 'og:title',
          content  : title
        },
        {
          property : 'og:description',
          content  : description
        },
        {
          property : 'og:image',
          content  : image
        },
        // -- twitter tag --
        {
          property : 'twitter:card',
          content  : 'summary'
        },
        {
          property : 'twitter:title',
          content  : title
        },
        {
          property : 'twitter:description',
          content  : description
        }
      ].concat(meta) }
    >
      <link 
        rel='dns-prefetch' 
        href='https://fonts.googleapis.com'
      />
      <link 
        rel='preload'
        as='style' 
        href='https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap'
        crossOrigin
      />
      <link 
        rel='preload'
        as='style' 
        href='https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap'
        crossOrigin
      />
      <link
        href='https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap'
        rel='stylesheet'
      />
      <link
        href='https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap'
        rel='stylesheet'
      />
      <link 
        rel='preload'
        href='https://www.youtube.com/embed/6M5XL_N2fII'
        as='document'
      />
      <link 
        rel='dns-prefetch' 
        href='https://www.youtube.com/embed/6M5XL_N2fII'
      />
      <link 
        rel='dns-prefetch' 
        href='https://api.ipify.org'
      />
      <link rel="dns-prefetch" href="https://api.ipify.org" />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang        : 'en',
  meta        : [],
  description : ''
}

SEO.propTypes = {
  description : PropTypes.string,
  lang        : PropTypes.string,
  meta        : PropTypes.arrayOf(PropTypes.object),
  title       : PropTypes.string.isRequired
}

export default SEO
